import { jwtDecode } from 'jwt-decode';

interface IJWTPayload {
    exp: number;
    // iat: number;
    // other JWT claims
  }
  
  const isTokenValid = (token: string): boolean => {
    try {
      const decoded = jwtDecode<IJWTPayload>(token);
      console.log('decoded token:', decoded);
      const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
      
      // Check if token is expired
      if (decoded.exp < currentTime) {
        console.log('Token expired');
        return false;
      }
  
      return true;
    } catch (error) {
      console.error('Invalid token:', error);
      return false;
    }
  }

  export default isTokenValid;