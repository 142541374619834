import React from 'react';
import { useEventSource } from './useEventSource';
import { Col, Container, Row } from 'react-bootstrap';
import BusLocationCard from '../BusLocationCard/BusLocationCard';
import { ISchoolEvent, SchoolEventMessageType } from './Event';

const EventDisplay: React.FC = () => {
  const { events, status } = useEventSource();

  if (events.length === 0) {
    return (
      <div className="text-center text-muted py-3">
        Waiting for server events...
      </div>
    );
  }

  const displayCard = (event: ISchoolEvent, index: number) => {
    switch (event.EventName) {
      case SchoolEventMessageType.BusLocationUpdatedEvent:
        return (<BusLocationCard event={event.EventData} />);
      case SchoolEventMessageType.BusNoActivityEvent:
        return (<span key={index}>{event.EventDescription}</span>);        
      default:
        return null;
    }
  }

  return (
    <Container fluid>
      <div>Connection Status: {status}, Count: {events.length}</div>
      <Row className="g-3">
        {events.map((event, index) => (
          <Col key={`${index}${event.EventKey}`} xs={12} sm={6} lg={3}>
            <div className="h-100 bg-light rounded p-3 border">
              {displayCard(event, index)}
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

// const BusLocationList = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//   gap: 24px;
//   padding: 24px;
//   background: #f5f7fa;
//   min-height: 100vh;
// `;

export default EventDisplay;