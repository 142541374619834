import React, { useEffect, useState } from 'react';
import { IBusLocation } from '../SseEvents/Event';
import Map, { IMapData } from "../../features/maps/Map";
import { GOOGLE_LIBS } from '../../constants/app-constants';
import { Battery, BatteryFull, BatteryMedium, BatteryLow } from 'lucide-react';
import moment from 'moment';


interface IEventCardProps {
  event: string;
}

const EventCard: React.FC<IEventCardProps> = ({ event }: IEventCardProps) => {
  // const mapInitialCenter: google.maps.LatLngLiteral = { lat: 17.541443, lng: 78.388241 };
  const eventData: IBusLocation = JSON.parse(event) as IBusLocation;
  const mapInitialCenter: google.maps.LatLngLiteral = { lat: eventData.Location.Latitude, lng: eventData.Location.Longitude };
  const [mapData, setMapData] = useState<IMapData>();
  const [showRoute, setShowRoute] = useState<boolean>(false);


  useEffect(() => {
    var data = [{
      id: eventData.MobileDeviceId, title: eventData.AttenderName,
      coords: {
        latitude: eventData.Location.Latitude, longitude: eventData.Location.Longitude,
        accuracy: eventData.Location.Accuracy, altitude: eventData.Location.Altitude,
        heading: eventData.Location.Heading, speed: eventData.Location.Speed
      }
    }];
    console.log('data:', data);
    setMapData(() => {
      return {
        routeData: data,
        locationData: data
      }
    });
  }, [eventData]);

  useEffect(() => {
    setTimeout(() => {
      setShowRoute(() => {
        return true;
      });
    }, 1000);

  }, []);

  const getBatteryIcon = (level: number) => {
    level = level * 100;
    if (level > 80) return <BatteryFull size={18} color="#4CAF50" />;
    if (level > 50) return <BatteryMedium size={18} color="#4CAF50" />;
    if (level > 30) return <BatteryMedium size={18} color="#FFA726" />;
    if (level > 20) return <Battery size={18} color="#FFA726" />;
    return <BatteryLow size={18} color="#F44336" />;
  };

  // const getRouteId = (routeId: string) => {
  //   if (routeId) {
  //     return routeId.split('. ')[0];
  //   }
  //   return '';
  // }

  return (
    <div style={styles.card}>
      <div style={styles.header}>
        <h4 style={{ ...styles.routeId, textAlign: 'center' }}>{eventData.RouteId}</h4>
        {/* <h4 style={styles.routeId}>{getRouteId(eventData.RouteId)}</h4> */}
      </div>
      <div style={styles.body}>
        <p style={{ ...styles.attenderName, textAlign: 'center' }}>{eventData.AttenderName}</p>
        <div style={styles.mapContainer}>
          <Map
            libs={GOOGLE_LIBS}
            mapCenter={mapInitialCenter}
            mapData={mapData}
            showRoute={showRoute}
            width='100%'
            height='200px'
          />
        </div>
      </div>
      <div style={styles.footer}>
        <span style={styles.datetime} title={moment(eventData.LastLocationDateTime).format('LLLL')}>
          {moment(eventData.LastLocationDateTime).fromNow()}
          {' • '}
          {moment(eventData.LastLocationDateTime).format('MMM D, h:mm A')}
        </span>
        <span style={styles.batteryLevel}>
          {getBatteryIcon(eventData.DeviceBatteryLevel)}
          <span style={styles.batteryText}>
            {Math.round(eventData.DeviceBatteryLevel * 100)}%
          </span>
        </span>
      </div>
    </div>
  )

};


const styles = {
  // card: {
  //   maxWidth: '100%',
  //   margin: '0',
  //   borderRadius: '12px',
  //   background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)',
  //   boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
  //   overflow: 'hidden',
  //   fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, sans-serif',
  //   border: '1px solid rgba(0,0,0,0.05)',
  //   transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  //   '&:hover': {
  //     transform: 'translateY(-4px)',
  //     boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
  //   },
  // },
  // header: {
  //   background: 'linear-gradient(135deg, #4a90e2 0%, #357abd 100%)',
  //   color: '#fff',
  //   padding: '20px',
  //   minHeight: '100px',
  //   // position: 'relative',
  //   overflow: 'hidden',
  // },
  // routeId: {
  //   margin: '0',
  //   fontSize: '24px',
  //   fontWeight: '600',
  //   letterSpacing: '-0.5px',
  // },
  // body: {
  //   padding: '24px',
  //   color: '#2d3748',
  // },
  // attenderName: {
  //   margin: '0 0 16px 0',
  //   fontSize: '18px',
  //   fontWeight: '500',
  //   color: '#4a5568',
  // },
  // mapContainer: {
  //   border: '2px solid #e2e8f0',
  //   borderRadius: '12px',
  //   padding: '12px',
  //   boxShadow: '0 2px 4px rgba(0,0,0,0.04)',
  //   backgroundColor: '#fff',
  //   margin: '16px 0',
  // },
  // footer: {
  //   padding: '16px 24px',
  //   backgroundColor: '#f8fafc',
  //   borderTop: '1px solid #e2e8f0',
  //   fontSize: '14px',
  //   color: '#718096',
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   alignItems: 'center',
  // },
  // batteryLevel: {
  //   display: 'inline-flex',
  //   alignItems: 'center',
  //   gap: '6px',
  //   padding: '8px 12px',
  //   borderRadius: '16px',
  //   backgroundColor: '#f1f5f9',
  //   color: '#475569',
  //   fontWeight: '500',
  // },
  // datetime: {
  //   color: '#64748b',
  //   fontSize: '14px',
  //   display: 'flex',
  //   alignItems: 'center',
  //   gap: '4px',
  // },
  // batteryText: {
  //   marginLeft: '4px',
  //   fontSize: '14px',
  // }
  card: {
    // position: 'relative',
    maxWidth: '400px',
    margin: '20px',
    padding: '0',
    borderRadius: '20px 20px 12px 12px',
    backgroundColor: '#FFD800', // School bus yellow
    boxShadow: '0 8px 20px rgba(0,0,0,0.15)',
    overflow: 'hidden',
    border: '2px solid #E6C200',
    transform: 'translateY(0)',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
    },
    // Bus front
    '&:before': {
      content: '""',
      //position: 'absolute',
      top: 0,
      left: '-20px',
      width: '40px',
      height: '100%',
      backgroundColor: '#FFD800',
      borderRadius: '10px',
      zIndex: -1,
    },
  },
  header: {
    backgroundColor: '#1a1a1a',
    color: '#fff',
    padding: '15px 20px',
    borderBottom: '4px solid #E6C200',
    // Windshield effect
    background: 'linear-gradient(135deg, #1a1a1a 0%, #2c2c2c 100%)',
    //position: 'relative',
    '&:after': {
      content: '""',
      //position: 'absolute',
      bottom: '-4px',
      left: '10%',
      width: '80%',
      height: '2px',
      background: 'rgba(255,255,255,0.1)',
    },
  },
  routeId: {
    margin: '0',
    fontSize: '24px',
    fontWeight: '600',
    // textAlign: 'center',
    letterSpacing: '1px',
    textShadow: '0 2px 4px rgba(0,0,0,0.2)',
  },
  body: {
    padding: '20px',
    backgroundColor: '#FFD800',
    // Side windows effect
    backgroundImage: `repeating-linear-gradient(
      90deg,
      transparent,
      transparent 60px,
      rgba(0,0,0,0.1) 60px,
      rgba(0,0,0,0.1) 80px
    )`,
  },
  attenderName: {
    margin: '0 0 15px 0',
    fontSize: '18px',
    fontWeight: '500',
    color: '#1a1a1a',
    // textAlign: 'center',
    padding: '8px',
    backgroundColor: 'rgba(255,255,255,0.9)',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  mapContainer: {
    border: '3px solid #1a1a1a',
    borderRadius: '10px',
    padding: '8px',
    backgroundColor: '#fff',
    boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.1)',
    // School bus stripe
    '&:before': {
      content: '""',
      //position: 'absolute',
      left: 0,
      right: 0,
      height: '8px',
      backgroundColor: '#000',
      bottom: '40px',
    },
  },
  footer: {
    padding: '15px 20px',
    backgroundColor: '#1a1a1a',
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // Bus bumper effect
    borderTop: '4px solid #E6C200',
  },
  datetime: {
    color: '#fff',
    fontSize: '14px',
    opacity: 0.9,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  batteryLevel: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '6px',
    padding: '6px 12px',
    borderRadius: '16px',
    backgroundColor: 'rgba(255,255,255,0.1)',
    color: '#fff',
    fontWeight: '500',
    // Headlight effect
    '&:before': {
      content: '""',
      //position: 'absolute',
      right: '20px',
      bottom: '15px',
      width: '12px',
      height: '12px',
      backgroundColor: '#FFD800',
      borderRadius: '50%',
      boxShadow: '0 0 10px #FFD800',
    },
  },
  batteryText: {
    marginLeft: '4px',
    fontSize: '14px',
  },
};


export default EventCard;