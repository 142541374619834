import { createApi } from '@reduxjs/toolkit/query/react';
import { ISchoolEvent, SchoolEventMessageType } from './Event';
import { SSE_CONFIG } from './sse-config';
import baseQueryWithReauth, { getAccesstokenFromRefreshToken, storeTokensInLocalStorage } from '../../common/BaseQueryWithReAuth';
import { RootState } from '../../app/store';
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';
import { SERVER_BASE_URL, SSE_EVENT_GET_URL } from '../../constants/service-constants';
import isTokenValid from '../../utils';
import { logout, setTokens } from '../../app/authSlice';

export const eventSourceApi = createApi({
  reducerPath: 'eventSourceApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getEventSourceUpdates: builder.query<ISchoolEvent[], void>({
      /**
       * The query function needs to return a valid QueryResult.
       * However, since SSE is a streaming API, we'll handle it in `onCacheEntryAdded`.
       */
      // query: () => ({
      //   url: '/api/event/dummyPingForTokenValidation',
      //   transformResponse: () => undefined
      // }),
      queryFn: () => ({ data: [] }), // Required but not used

      /**
       * onCacheEntryAdded is invoked when a component subscribes to a cache entry.
       * This is where we establish the SSE connection.
       */
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState, dispatch }
      ) {

        const controller = new AbortController();

        // Get the auth token from the Redux store
        const token = (getState() as RootState).auth.accessToken;

        // Initialize the fetchEventSource with the passed AbortController's signal
        try {
          await fetchEventSource(`${SERVER_BASE_URL}${SSE_EVENT_GET_URL}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'text/event-stream',
            },
            signal: controller.signal,

            /**
             * onopen is called when the connection is established.
             */
            async onopen(response) {
              if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
                console.log('SSE Connected', response);
                //arg.onConnected(true);
                // Clear existing events on reconnection
                updateCachedData(() => []);
                return; // everything's good
              } else if (response.status === 401) {
                console.log('SSE Connection Unauthorized:', response);
                // check if the refresh token is valid
                let refreshToken = (getState() as RootState).auth.refreshToken;
                if (refreshToken !== null && !isTokenValid(refreshToken)) {
                  // if not, logout the user
                  dispatch(logout());
                  return;
                } else {
                  let token = await getAccesstokenFromRefreshToken(refreshToken);
                  if (token !== null) {
                    dispatch(setTokens(token));
                    await storeTokensInLocalStorage(token);
                  } else {
                    console.warn(`Refresh token has also expired! Logging out.`);
                    dispatch(logout());
                  }
                }
                return;
              } else if (response.status >= 400 && response.status < 500) { // && response.status !== 429) {
                // client-side errors are usually non-retriable:
                //arg.onConnected(false);
                throw new Error(`Unexpected status code ${response.status}`);
              } else {
                //arg.onConnected(false);
                throw new Error(`Unexpected status code ${response.status}`);
              }
            },

            /**
             * onmessage is called when a new SSE message is received.
             */
            onmessage: (event) => {
              console.log('SSE Message Received:', event);
              if (event.data) {
                const parsedData: ISchoolEvent = JSON.parse(event.data);
                try {
                  switch (parsedData.EventName) {
                    case SchoolEventMessageType.OnConnectEvent:
                      console.log('SSE Open:', event);
                      break;
                    case SchoolEventMessageType.BusLocationUpdatedEvent:
                      console.log('BusLocationUpdatedEvent', parsedData);
                      updateCachedData((draft) => {
                        draft.push(parsedData);
                        if (draft.length > SSE_CONFIG.MAX_EVENTS) {
                          draft.shift();
                        }
                      });
                      break;
                    case SchoolEventMessageType.DummyPingEvent:
                      // do nothing on this event
                      break;
                    default:
                      console.log('SSE Event:', parsedData);
                      updateCachedData((draft) => {
                        draft.push(parsedData);
                        if (draft.length > SSE_CONFIG.MAX_EVENTS) {
                          draft.shift();
                        }
                      });
                      break;
                  }

                } catch (error) {
                  console.error('Error parsing SSE data:', error);
                }
              }
            },

            /**
             * onerror is called when an error occurs.
             * If the connection fails, the library will automatically retry.
             */
            onerror(err) {
              console.error('SSE Error:', err);
              // Return false to close the connection if desired
              // return false; 
            },
            /**
             * closeOnError determines if the connection should be closed on error.
             */
            // closeOnError: true,
          });
        } catch (error: any) {
          if (error.name === 'AbortError') {
            console.log('SSE Connection Aborted:', error);
          }
          console.error('SSE Connection Error:', error);
        }

        /**
         * cleanup function to close the SSE connection when the cache entry is removed
         */
        await cacheEntryRemoved;
        // Note: fetchEventSource handles the aborting internally when the connection is closed
        controller.abort();
      },
    }),
  }),
});

export const { useGetEventSourceUpdatesQuery } = eventSourceApi;