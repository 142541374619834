import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authApi } from '../features/login/service/loginApiSlice';
import { pokemonApi } from '../features/poke/services/pokemonSlice';
import authReducer from './authSlice';
import appReducer from './appSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { busRoutesApi } from '../features/busRoutes/services/busRoutesSlice';
import { studentApi } from '../features/student/services/studentSlice';
import { idCardApi } from '../features/idcard/services/idCardSlice';
import { mapDataApi } from '../features/maps/services/mapDataSlice';
import { staffApi } from '../features/staff/services/staffSlice';
import { reportsApi } from '../features/reports/services/reportsSlice';
import { busRouteLandmarksApi } from '../features/busRoutes/services/busRouteLandmarksSlice';
import { eventSourceApi } from '../components/SseEvents/eventSourceApi';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [pokemonApi.reducerPath]: pokemonApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [busRoutesApi.reducerPath]: busRoutesApi.reducer,
    [studentApi.reducerPath]: studentApi.reducer,
    [staffApi.reducerPath]: staffApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [idCardApi.reducerPath]: idCardApi.reducer,
    [mapDataApi.reducerPath]: mapDataApi.reducer,
    [busRouteLandmarksApi.reducerPath]: busRouteLandmarksApi.reducer,
    [eventSourceApi.reducerPath]: eventSourceApi.reducer,
    auth: authReducer,
    app: appReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(pokemonApi.middleware)
      .concat(authApi.middleware)
      .concat(busRoutesApi.middleware)
      .concat(studentApi.middleware)
      .concat(idCardApi.middleware)
      .concat(mapDataApi.middleware)
      .concat(staffApi.middleware)
      .concat(reportsApi.middleware)
      .concat(busRouteLandmarksApi.middleware)
      .concat(eventSourceApi.middleware)
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
