import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { IDCARD_IMAGE_GET_URL, REFRESHTOKEN_GET_URL, SERVER_BASE_URL } from '../constants/service-constants';
import { RootState } from '../app/store';
import { logout, setTokens } from '../app/authSlice';
import { IRefreshTokenResponse } from '../features/login/models/IRefreshToken';
import { IValidateOtpResponse } from '../features/login/models/ILoginModels';
// import { useNavigate } from 'react-router-dom';


var tokenDuplicate = '';
export async function getIdImage(userNum: string) {
  // console.log('Access Duplicate Token: ', tokenDuplicate);
  var res = await fetch(`${IDCARD_IMAGE_GET_URL}${userNum}`, {
    headers: [['Authorization', `Bearer ${tokenDuplicate}`]]    
  });
  if (res.ok) {
    //return await res.blob;
    console.log('success....!!!');
    return res.blob();
  } else {
    console.error('Some error while getting image: ', res);
  }
}


export const getAccesstokenFromRefreshToken = async (refreshToken: string | null): Promise<IRefreshTokenResponse | null> => {
  if (refreshToken !== null) {
    const refreshResult = await fetch(`${SERVER_BASE_URL}${REFRESHTOKEN_GET_URL}`, {
      headers: [['authorization', `Bearer ${refreshToken}`]]
    });
    if (refreshResult.ok) {
      // store the new tokens
      let newTokens = (await refreshResult.json()) as IRefreshTokenResponse;
      console.warn('refreshResult.data', newTokens);
      //api.dispatch(setTokens(d));
      tokenDuplicate = newTokens.bearerToken;   // set the tokenDuplicate for local use.
      return newTokens;
    } else {
      console.warn(`Refresh token has also expired! Logging out.`);        
      //api.dispatch(logout());
      // navigate(`/login`);
    }
  }
  return null;
}

export const storeTokensInLocalStorage = async (token: IRefreshTokenResponse) => {
  // there is no error on this second request.
  // the token refresh succeeded. Store the
  // tokens for future use.
  let sauth = localStorage.getItem('auth');
  if (sauth !== null) {
    let a = JSON.parse(sauth) as IValidateOtpResponse;
    a.bearerToken = token.bearerToken;
    a.refreshToken = token.refreshToken;
    localStorage.setItem('auth', JSON.stringify(a));
  }
}



const baseQuery = fetchBaseQuery({
  baseUrl: `${SERVER_BASE_URL}`,
  prepareHeaders: (headers, { getState, extra }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.accessToken;
    tokenDuplicate = token ?? '';   // set the tokenDuplicate for local use.
    // const token = (extra as {token:string}).token ? (extra as {token:string}).token : oldToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },

});
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // const navigate = useNavigate();
  let result = await baseQuery(args, api, extraOptions);
  // console.log('baseQueryWithReauth: ', result);
  if (result.error && (result.error.status === 403 || result.error.status === 401)) {
    
    // try to get a new token using the refreshtoken    
    const refreshToken = (api.getState() as RootState).auth.refreshToken;
    try {
      // Unable to get this to work as this keeps using the expired token!
      // I do not know how to pass the token to the prepareHeaders function in baseQuery      
      // const refreshResult = await baseQuery({
      //   url: `${REFRESHTOKEN_GET_URL}`,
      //   method: 'GET',
      //   headers: [['authorization', `Bearer ${token}`]]
      // }, api, extraOptions);

      // // so using fetch!
      // const refreshResult = await fetch(`${SERVER_BASE_URL}${REFRESHTOKEN_GET_URL}`, {
      //   headers: [['authorization', `Bearer ${token}`]]
      // });
      // if (refreshResult.ok) {
      //   // store the new tokens
      //   let d = (await refreshResult.json()) as IRefreshTokenResponse;
      //   console.warn('refreshResult.data', d);
      //   api.dispatch(setTokens(d));
      //   tokenDuplicate = d.bearerToken;   // set the tokenDuplicate for local use.

      //   // retry the initial query
      //   result = await baseQuery(args, api, extraOptions);
      //   if (!result.error) {
          
      //     // there is no error on this second request.
      //     // the token refresh succeeded. Store the
      //     // tokens for future use.
      //     let sauth = localStorage.getItem('auth');
      //     if (sauth !== null) {
      //       let a = JSON.parse(sauth) as IValidateOtpResponse;
      //       a.bearerToken = d.bearerToken;
      //       a.refreshToken = d.refreshToken;
      //       localStorage.setItem('auth', JSON.stringify(a));
      //     }

      //   }
      // } else {
      //   console.warn(`Refresh token has also expired! Logging out.`);        
      //   api.dispatch(logout());
      //   // navigate(`/login`);
      // }
      let token = await getAccesstokenFromRefreshToken(refreshToken);
      if (token !== null) {
        api.dispatch(setTokens(token));
        
        // retry the initial query
        result = await baseQuery(args, api, extraOptions);
        if (!result.error) {
          await storeTokensInLocalStorage(token);
        }
      } else {
        console.warn(`Refresh token has also expired! Logging out.`);        
        api.dispatch(logout());
        // navigate(`/login`);
      }
    } catch (err) {
      console.error('Error occurred getting refresh token', err);
    }

  }
  return result;
}

export default baseQueryWithReauth;