
// This enum matches the server side EventType enum
export enum SchoolEventMessageType {
  OnConnectEvent = "OnConnectEvent",
  DeviceAssignmentEvent = "DeviceAssignmentEvent",
  DeviceUnassignmentEvent = "DeviceUnassignmentEvent",
  BusLocationUpdatedEvent = "BusLocationUpdatedEvent",
  BusStartendEvent = "BusStartendEvent",
  BusReturnedEvent = "BusReturnedEvent",
  BusNoActivityEvent = "BusNoActivityEvent",
  UserEvent = "UserEvent",
  ErrorEvent = "ErrorEvent",
  DummyPingEvent = "DummyPingEvent",    // returned when the server receives a dummy ping for token validation
}


export interface ISchoolEvent {
  EventName: string,
  EventKey: string,
  EventDateTime: string,
  EventDescription: string,
  EventData: string  //| IDeviceEvent | IUserEvent | IDeviceAssignmentEvent
}

export interface ILocationCoordinates {
  Accuracy: number;
  Altitude: number;
  Heading: number;
  Latitude: number;
  Longitude: number;
  Speed: number;
}

export interface IBusLocation {
  RouteId: string;
  RouteName: string;
  BusDirection: string;
  FirstLocationDateTime: string; // ISO 8601 format Zulu time
  LastLocationDateTime: string; // ISO 8601 format Zulu time
  NumberOfStudentsInBus: number;
  MobileDeviceId: string;
  MobileDevicePhoneNumber: string;
  StaffId: number;
  AttenderName: string;
  Location: ILocationCoordinates;
  IsBusOk: boolean;
  DeviceBatteryLevel: number;
}

export interface IDeviceEvent {
  DeviceId: string;
  EmployeeId: number;
  EventDatetime: string;
  Coords: ICoordinates;
  Location: IMyGeoJsonPoint;
  LocationComment: string;
  RouteId: string;
  RouteName: string;
  BatteryLevel: number;
  Mocked: boolean;
}

export interface IUserEvent {
  DeviceId: string;
  EmployeeId: number;
  EventDatetime: string;
  Coords: ICoordinates;
  Location: IMyGeoJsonPoint;
  LocationComment: string;
  RouteId: string;
  RouteName: string;
  BatteryLevel: number;
  Mocked: boolean;
}


export interface IDeviceAssignmentEvent {
  MobileDeviceId: string;
  MobileDeviceName: string;
  RouteId: string;
  RouteName: string;
  EmployeeId: number;
  Datetime: string;
  DeviceDatetime: string;
  ServerDatetime: string;
  AppInfo: string;
}

export interface IConnectionMessage {
  message: string;
}

export interface ICoordinates {
  Latitude: number;
  Longitude: number;
}

export interface IMyGeoJsonPoint {
  Coordinates: number[];
  Type: string;
}