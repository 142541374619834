import { Button, Card } from 'react-bootstrap';
import { useAppDispatch } from '../../app/store';
import StaffGridDisplay from '../../components/StaffGridDisplay/StaffGridDisplay';
import { staffApi } from './services/staffSlice';

const AllStaff = () => {
    const dispatch = useAppDispatch();


    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginBottom: "20px",
                }}
            >
                <Card style={{ textAlign: "left", width: "100%" }}>
                    <Card.Header>All Staff</Card.Header>
                    <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">
                            <Button onClick={() => dispatch(staffApi.util.resetApiState())}>Refresh Data</Button>
                        </Card.Subtitle>
                        <StaffGridDisplay></StaffGridDisplay>

                    </Card.Body>
                    <Card.Footer>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                            }}
                        >
                        </div>
                    </Card.Footer>
                </Card>

            </div>
        </>
    )

}


export default AllStaff;